'use strict';
var $ = window.$;

$(document).ready(function () {

  Fancybox.bind()
  // $.fancybox.defaults.touch = false;
  // $.fancybox.defaults.infobar = false;

  // Слайдеры
  $('[reviews-slider]').each(function () {
    let $block = $(this);
    let $init = $block.find('[class*="__list"]');
    let $next = $block.find('[class*="_next"]');
    let $prev = $block.find('[class*="_prev"]');
    let $dots = $block.find('.slider-dots');

    $init.slick({
      arrows: true,
      dots: true,
      slidesToShow: 1,
      infinite: true,
      nextArrow: $next,
      prevArrow: $prev,
      appendDots: $dots,
      mobileFirst: true,
    });
  });

  $('[safety-slider]').each(function () {
    let $block = $(this);
    let $init = $block.find('[class*="__list"]');
    let $next = $block.find('[class*="_next"]');
    let $prev = $block.find('[class*="_prev"]');

    $init.slick({
      arrows: true,
      dots: false,
      slidesToShow: 1,
      infinite: true,
      variableWidth: true,
      nextArrow: $next,
      prevArrow: $prev,
      mobileFirst: true,
    });
  });

  // Открытие мобильного меню
  $('.js-toggle-menu').on('click', function () {
    $('#mobile-menu').toggleClass('is-open');
    $('#hamburger').toggleClass('is-open');
  });

  $('.js-close-menu').on('click', function () {
    $('#mobile-menu').removeClass('is-open');
    $('#hamburger').removeClass('is-open');
  });

  $('.js-open-dropdown').on('click', function () {
    $(this).parent('.faq-block__item').toggleClass('isOpen');
  });

  // плавная прокрутка
  $('a[href*="#"]').on('click', function (event) {
    event.preventDefault();
    const id = $(this).attr('href');
    const top = $(id).offset().top;
    $('#mobile-menu').removeClass('is-open');
    $('body,html').animate({
      scrollTop: top,
    }, 500);
  });

  if (readCookie('close_cookie_window') !== 'true') {
    $('.cookies-window').removeClass('hide');
  }

  $('.js_close_cokies').on('click', function () {
    $('.cookies-window').addClass('hide');
    writeCookie('close_cookie_window', 'true', 5);
  });

  function writeCookie(name, val, expires) {
    const date = new Date;
    date.setDate(date.getDate() + expires);
    document.cookie = name+"="+val+"; path=/; expires=" + date.toUTCString();
  }

  function readCookie(name) {
    const matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
});
